
























































































































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import {
  BModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BvEvent,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BOverlay,
  VBTooltip,
} from 'bootstrap-vue'
import {
  notEmpty,
  ValueValidation,
  ValidationGroup,
  greater,
} from '@/helpers/validationOld'
import { urlFormatter } from '@/helpers/formatters'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
const flatPickr = require('vue-flatpickr-component')
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { webinarInstancesStore } from '@/store'
import { copyTextToClipboard } from '@/helpers/copy'
import { showError, showMessage } from '@/helpers/notifications'
import WayupSelect from '@/components/WayupSelect.vue'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import PromocodeDetailsModal from '@/components/promocodes/PromocodeDetailsModal.vue'
import PromocodeModal from '@/components/promocodes/PromocodeEditModal'
import { getCourses, ICourse } from '@/api/courses'

interface IWebinarInstanceData {
  dateStart: string
  youtube: string
  videoUrl: string
  promocode: {
    id: number
    discount: number
    courses: number[]
    dateStart: string
    dateEnd: string
    isActive: true
  }
  videoLength: number
  aliasFinal: string
}

@Component({
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BOverlay,
    BInputGroupAppend,
    BInputGroup,
    WayupSelect,
    flatPickr,
    BButton,
    PromocodeDetailsModal,
    PromocodeModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
})
export default class WebinarInstanceEditModal extends Vue {
  @Ref() readonly promocodeDetailsModal!: { show: (promocode: object) => void }
  @Ref() readonly promocodeEditModal!: PromocodeModal

  state: 'create' | 'edit' = 'create'
  pending = false

  flatPickrConfig = {
    enableTime: true,
    dateFormat: 'Y-m-d H:i:S',
    altInput: true,
    altFormat: 'l d M Y H:i',
    time_24hr: true,
    locale: Russian,
  }

  courses: ICourse[] = []

  validation = new ValidationGroup()
  editValidation = new ValidationGroup()

  dateStart = new ValueValidation({
    initialValue: '',
    validators: [notEmpty()],
    validationGroup: this.validation,
  })
  youtube = new ValueValidation({
    initialValue: '',
    validators: [notEmpty()],
    validationGroup: this.validation,
  })
  aliasFinal = new ValueValidation({
    initialValue: `${webinarInstancesStore.alias}-`,
    validators: [notEmpty()],
    validationGroup: this.validation,
  })
  discount = new ValueValidation({
    initialValue: 0,
    validators: [notEmpty(), greater(0)],
    validationGroup: this.validation,
  })
  promocodeTimer = new ValueValidation({
    initialValue: 72,
    validators: [notEmpty(), greater(0)],
    validationGroup: this.validation,
  })
  videoLengthFormatted = new ValueValidation({
    initialValue: 0,
    validators: [notEmpty(), greater(0, 'Введите длину видео')],
    validationGroup: this.validation,
  })
  videoLength: {
    seconds: number | null
    minutes: number | null
    hours: number | null
  } = {
    seconds: null,
    minutes: null,
    hours: null,
  }
  selectedCourse = new ValueValidation<{ id: number } | null>({
    initialValue: null,
    validators: [notEmpty()],
    validationGroup: this.validation,
  })

  id: number | null = null
  promocode: object | null = null

  get modalTitle() {
    return this.state === 'create'
      ? 'Создание экземпляра'
      : 'Редактирование экземпляра'
  }

  get okTitle() {
    return this.state === 'create' ? 'Создать' : 'Сохранить'
  }

  get urlFormatter() {
    return urlFormatter
  }

  get mscUrl() {
    return process.env.VUE_APP_MSC_URL
  }

  @Watch('videoLength', { deep: true })
  onTimerChange() {
    this.videoLengthFormatted.value =
      (this.videoLength.hours || 0) * 3600 +
      (this.videoLength.minutes || 0) * 60 +
      +(this.videoLength.seconds || 0)
  }

  @Watch('dateStart.value')
  onDateStartChanged(date: string) {
    if (date) {
      const temp = date.slice(0, 10)
      this.aliasFinal.value = `${webinarInstancesStore.alias}-${temp.slice(
        8,
        10,
      )}${temp.slice(5, 7)}${temp.slice(0, 4)}`
    } else {
      this.aliasFinal.value = `${webinarInstancesStore.alias}-`
    }
  }

  async mounted() {
    try {
      this.courses = (await getCourses()).filter(
        c => c.isActive && c.type === 'paid',
      )
    } catch (error) {
      console.error(error)
      showError('При загрузке курсов произошла ошибка')
    }

    this.editValidation.addValue(this.dateStart)
    this.editValidation.addValue(this.aliasFinal)
    this.editValidation.addValue(this.youtube)
    this.editValidation.addValue(this.videoLengthFormatted)
  }

  async show(id?: number) {
    if (id) {
      try {
        const response: AxiosResponse<{
          instance: IWebinarInstanceData
        }> = await axios.get(`v1/webinar/instances/${id}`)
        this.fillData(response.data.instance)
        this.state = 'edit'
        this.id = id
      } catch (error) {
        console.error(error)
        showError('При получении события произошла ошибка')
        return
      }
    } else {
      this.state = 'create'
    }
    this.$bvModal.show('webinar-instance-edit-modal')
  }

  fillData(data: IWebinarInstanceData) {
    this.aliasFinal.value = data.aliasFinal
    this.youtube.value = data.youtube
    this.dateStart.value = data.dateStart
    this.videoLengthFormatted.value = data.videoLength

    this.videoLength.hours = Math.floor(data.videoLength / 3600)
    this.videoLength.minutes = Math.floor((data.videoLength % 3600) / 60)
    this.videoLength.seconds = Math.floor(data.videoLength % 60)

    this.promocode = data.promocode
  }

  async handleOk(e: BvEvent) {
    if (!this.validate()) {
      showError('Некорректно заполнены поля')
      return e.preventDefault()
    }

    const date = new Date(this.dateStart.value)
    date.setHours(date.getHours() + this.promocodeTimer.value)
    const dateEnd = `${date.toISOString().slice(0, 10)} ${date
      .toISOString()
      .slice(11, 19)}`

    this.pending = true
    let result = false

    if (this.state === 'create') {
      result = await webinarInstancesStore.createInstance({
        dateStart: this.dateStart.value,
        youtube: this.youtube.value,
        videoLength: this.videoLengthFormatted.value,
        aliasFinal: this.aliasFinal.value,
        courseId: this.selectedCourse.value!.id,
        promocode: {
          discount: this.discount.value,
          dateStart: this.dateStart.value,
          dateEnd: dateEnd,
        },
        videoUrl: 'placeholder',
      })
    } else {
      result = await webinarInstancesStore.updateInstance({
        id: this.id!,
        dateStart: this.dateStart.value,
        youtube: this.youtube.value,
        videoLength: this.videoLengthFormatted.value,
        aliasFinal: this.aliasFinal.value,
        videoUrl: 'placeholder',
      })
    }

    this.pending = false

    if (!result) {
      e.preventDefault()
    }
  }

  validate() {
    if (this.state === 'create') {
      return this.validation.validate()
    }
    return this.editValidation.validate()
  }

  reset() {
    this.dateStart.value = ''
    this.youtube.value = ''
    this.aliasFinal.value = `${webinarInstancesStore.alias}-`
    this.discount.value = 0
    this.promocodeTimer.value = 72
    this.videoLengthFormatted.value = 0
    this.videoLength = {
      seconds: null,
      minutes: null,
      hours: null,
    }
    this.selectedCourse.value = null

    this.id = null
    this.promocode = null

    this.$nextTick(() => this.validation.resetErrors())
  }

  copyUrl() {
    if (
      copyTextToClipboard(
        `${this.mscUrl}/webinars/${this.aliasFinal.value}`,
        document.getElementById('alias')!,
      )
    ) {
      showMessage('url успешно скопирован')
    }
  }

  editPromocode() {
    this.promocodeEditModal.show(this.promocode as any) //TODO
  }

  showPromocodeDetails() {
    this.promocodeDetailsModal.show(this.promocode!)
  }

  timerFormatter(value: number) {
    if (value > 59) {
      return 59
    }
    if (value < 0) {
      return 0
    }

    return value
  }
}
