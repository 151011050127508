









































































import { Vue, Component, Ref } from 'vue-property-decorator'
import { webinarInstancesStore } from '@/store'
const { VueGoodTable } = require('vue-good-table')
import '@core/scss/vue/libs/vue-good-table.scss'
import { formatDateTime } from '@/helpers/formatters'
import { showMessage } from '@/helpers/notifications'
import WebinarInstanceEditModal from '@/components/modals/WebinarInstanceEditModal.vue'
import { copyTextToClipboard } from '@/helpers/copy'
import DeleteConfirmationModal from '@/components/DeleteConfirmationModal.vue'
import { IWebinarInstanceSimple } from '@/store/webinarInstancesStore'

@Component({
  components: {
    VueGoodTable,
    WebinarInstanceEditModal,
    DeleteConfirmationModal,
  },
})
export default class WebinarInstances extends Vue {
  @Ref('deleteModal') readonly deleteConfirmationModal!: {
    show: (title: string) => Promise<boolean>
  }
  @Ref()
  readonly webinarInstanceEditModal!: { show: (id?: number) => void }

  columns: Array<{
    field: string
    label: string
    type?: string
    sortable?: boolean
    width?: string
  }> = [
    {
      field: 'aliasFinal',
      label: 'url',
      sortable: false,
    },
    {
      field: 'dateStart',
      label: 'Дата старта',
      sortable: false,
    },
    {
      field: 'membersCount',
      label: 'Лидов',
      sortable: false,
    },
    {
      field: 'viewsCount',
      label: 'Зашло',
      sortable: false,
    },
    {
      field: 'withheldCount',
      label: 'Удержано',
      sortable: false,
    },
    {
      field: 'ordersCount',
      label: 'Броней',
      sortable: false,
    },
    {
      field: 'conversion',
      label: 'Конверсия',
      sortable: false,
    },
    {
      field: 'buttons',
      label: '',
      sortable: false,
    },
  ]
  paginationOptions = {
    enabled: true,
    mode: 'pages',
    perPageDropdown: [10, 50, 100],
    dropdownAllowAll: false,
    nextLabel: '',
    prevLabel: '',
    rowsPerPageLabel: 'на странице',
    ofLabel: 'из',
    pageLabel: 'страница',
  }
  isLoading = false

  get instances() {
    return webinarInstancesStore.instances
  }

  get totalRows() {
    return webinarInstancesStore.instancesCount
  }

  get formatDateTime() {
    return formatDateTime
  }

  get mscUrl() {
    return process.env.VUE_APP_MSC_URL
  }

  onPageChange(params: { currentPage: number; currentPerPage: number }) {
    webinarInstancesStore.setPaginationOptions({
      page: params.currentPage,
      perPage: params.currentPerPage,
    })
    webinarInstancesStore.fetchInstances()
  }

  onPerPageChange(params: { currentPage: number; currentPerPage: number }) {
    webinarInstancesStore.setPaginationOptions({
      page: 1,
      perPage: params.currentPerPage,
    })
    webinarInstancesStore.fetchInstances()
  }

  async remove(id: number, title: string) {
    if (await this.deleteConfirmationModal.show(title)) {
      await webinarInstancesStore.deleteInstance(id)
    }
  }

  edit(id: number) {
    this.webinarInstanceEditModal.show(id)
  }

  add() {
    this.webinarInstanceEditModal.show()
  }

  conversionText(webinar: IWebinarInstanceSimple) {
    const a =
      webinar.viewsCount && webinar.membersCount
        ? Math.round((webinar.viewsCount / webinar.membersCount) * 100)
        : 0
    const b =
      webinar.viewsCount && webinar.withheldCount
        ? Math.round((webinar.withheldCount / webinar.viewsCount) * 100)
        : 0
    const c =
      webinar.withheldCount && webinar.ordersCount
        ? Math.round((webinar.ordersCount / webinar.withheldCount) * 100)
        : 0

    return `${a}% / ${b}% / ${c}%`
  }

  copyUrl(url: string) {
    if (copyTextToClipboard(`${this.mscUrl}/webinars/${url}`)) {
      showMessage('Url успешно скопирован')
    }
  }
}
